<template>
  <div class="fd-px-5 fd-pt-6 fd-pb-3 fd-w-full fd-relative fd-bg-white fd-rounded-lg" style="height: auto; min-height: 100%">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start">
      <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('connectedPage.rsInformation') }}</span>
    </div>
    <div>
      <validation-observer ref="inviteValidation">
        <b-form @submit.prevent>
          <div class="fd-w-full fd-flex fd-items-center">
            <b-form-group
              :label="$t('global.name')"
              label-for="name"
              class="fd-relative fd-mt-8 fd-w-full fd-mr-4 fd-text-sm fd-font-normal fd-text-theme-9"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  placeholder="Name"
                  v-model="form.name"
                />
                <small class="fd-absolute fd-left-2 fd-top-full fd-mt-1 fd-text-red-500">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('global.email')"
              label-for="email"
              class="fd-relative fd-mt-8 fd-w-full fd-text-sm fd-font-normal fd-text-theme-9"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  :type="'email'"
                  placeholder="Email"
                  v-model="form.email"
                />
                <small class="fd-absolute fd-left-2 fd-top-full fd-mt-1 fd-text-red-500">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="fd-mt-3">
            <label for="message">{{ $t('global.message') }}</label>
            <b-form-textarea id="message" v-model="form.request_message" rows="20" max-rows="20" style="font-size: 0.75rem !important; line-height: 1rem; !important;" />
          </div>

          <div class="fd-flex fd-justify-end fd-mt-16">
            <b-button :type="'submit'" variant="primary" @click="validationForm">{{ $t('internalAgents.invite') }}</b-button>
          </div>
        </b-form>
      </validation-observer>

    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { BFormGroup, BFormInput, BButton, BForm, BFormTextarea } from 'bootstrap-vue'
import TextEditor from "@/views/components/global/TextEditor";
import toast from "@/mixins/toast";
export default {
  name: "Invite",
  components: { TextEditor, BFormGroup, BFormInput, BButton, BForm, ValidationProvider, ValidationObserver, BFormTextarea },
  mixins: [toast],
  data() {
    return {
      form: {
        name: '',
        email: '',
        request_message: ''
      },
      required,
      email
    }
  },
  methods: {
    validationForm() {
      this.$refs.inviteValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('rs/invite', this.form).then(response => {
            this.makeToast(response.data.message)
            return this.$router.push({ name: 'rsManagement.connected' })
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>